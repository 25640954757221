
import {
    styled,
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Button,
    Link,
    useTheme,
    useMediaQuery,
    TextField,
    Divider,
    Snackbar,
    IconButton,
} from '@mui/material';
import "./i18n";
import { useTranslation } from "react-i18next";
import ReactCardFlip from 'react-card-flip';
import React, { useState, useRef } from 'react';
import QRCode from "react-qr-code";
import { collection, addDoc } from "firebase/firestore";
import {db} from './firebase';
import CloseIcon from '@material-ui/icons/Close';

const ImgLemonGifts = styled(Box)(({ theme }) => ({
    width: 'auto', 
    height: 92, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Limoes-gifts.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
        height: 53,
        width: 'auto'
    },
}));

const ImQRCodeGifts = styled(QRCode)(({ theme }) => ({
    height: 177, 
    maxWidth: "100%", 
    width: "100%",
    marginTop: 25
}));

const GiftsTitleTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 40,
    fontWeight: 100,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
}));

const GiftsDescTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 10,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
}));

const GiftsIbanTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 10,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        marginTop: 10,
      },
}));

const GiftsPixTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 10,
    height: 50,
}));

const GiftsPriceTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 21,
    fontWeight: 500,
    textAlign: 'center',
    height: 40,
}));

const GiftsButton = styled(Button)(({ theme }) => ({
    width: 145,
    height: 36,
    borderRadius: 'initial',
    backgroundColor: "#0274D3",
    '&:hover': {
      backgroundColor: "#004E90"
    },
}));

const GiftsMoreButton = styled(Button)(({ theme }) => ({
    width: 145,
    height: 36,
    borderRadius: 'initial',
    backgroundColor: "#535158",
    '&:hover': {
      backgroundColor: "#37363a"
    },
}));

const GiftsSendButton = styled(Button)(({ theme }) => ({
    width: 145,
    height: 36,
    borderRadius: 'initial',
    backgroundColor: "#0274D3",
    '&:hover': {
      backgroundColor: "#004E90"
    },
}));

const GiftsButtonTextTypography = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    fontWeight: 300,
}));

let initialItems = [
    {
        url: "gifts-01.png",
        description: "Pra não dizer que não te dei nada, toma aqui 50 reais.",
        price: "R$ 50,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio520400005303986540550.005802BR5918Silvio Casamassima6009SAO PAULO621405102zyIFrWF6063045B84",
    },
    {
        url: "gifts-02.png",
        description: "Tampão de ouvido para noiva enquanto noivo ronca.",
        price: "R$ 72,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio520400005303986540572.005802BR5918Silvio Casamassima6009SAO PAULO621405104j18dpA1cy63043121",
    },
    {
        url: "gifts-03.png",
        description: "Cobertor para a noiva que está sempre coberta de razão.",
        price: "R$ 95,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio520400005303986540595.005802BR5918Silvio Casamassima6009SAO PAULO621405107jFzM7nRHP6304836D",
    },
    {
        url: "gifts-04.png",
        description: "Taxa pra noiva não jogar o buquê pra sua namorada.",
        price: "R$ 121,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406121.005802BR5918Silvio Casamassima6009SAO PAULO62140510i8SkTaqKiT6304EC4F",
    },
    {
        url: "gifts-05.png",
        description: "Rolo de macarrão caso o noivo não se comporte.",
        price: "R$ 256,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406256.005802BR5918Silvio Casamassima6009SAO PAULO62140510eQuDhArEwS63040AD6",
    },
    {
        url: "gifts-06.png",
        description: "Patrocine a despedida do noivo.",
        price: "R$ 321,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406321.005802BR5918Silvio Casamassima6009SAO PAULO62140510fnJuY4w3kd6304BEAC",
    },
    {
        url: "gifts-07.png",
        description: "Cota amigos para sempre.",
        price: "R$ 375,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406375.005802BR5918Silvio Casamassima6009SAO PAULO62140510AHXSfy9aN663042C94",
    },
    {
        url: "gifts-08.png",
        description: "Adote um boleto atrasado.",
        price: "R$ 472,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406472.005802BR5918Silvio Casamassima6009SAO PAULO62140510rHvtWtMttj63040EAF",
    },
    {
        url: "gifts-09.png",
        description: "Seja nosso parente favorito.",
        price: "R$ 599,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406599.005802BR5918Silvio Casamassima6009SAO PAULO62140510dq2FYcYyni6304F9B6",
    },
    {
        url: "gifts-10.png",
        description: "Patrocine a despedida da noiva.",
        price: "R$ 666,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406666.005802BR5918Silvio Casamassima6009SAO PAULO62140510OFGjxAa8XJ63047C5B",
    },
    {
        url: "gifts-11.png",
        description: "Assaltando o frigobar.",
        price: "R$ 785,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406785.005802BR5918Silvio Casamassima6009SAO PAULO62140510XrYvPVQODF6304A625",
    },
    {
        url: "gifts-12.png",
        description: "Comprinhas da noiva na lua de mel.",
        price: "R$ 884,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406884.005802BR5918Silvio Casamassima6009SAO PAULO62140510WhJU6mg3zq630440BB",
    },
    {
        url: "gifts-13.png",
        description: "Bagagem extra para volta da lua de mel.",
        price: "R$ 925,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406925.005802BR5918Silvio Casamassima6009SAO PAULO62140510mw87FTc6036304FFC0",
    },
    {
        url: "gifts-14.png",
        description: "Prioridade no quarto de visita na casa dos noivos.",
        price: "R$ 989,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio5204000053039865406989.005802BR5918Silvio Casamassima6009SAO PAULO62140510VXkhsBGpaI63040204",
    },
    {
        url: "gifts-15.png",
        description: "Valor redondo pra quem tem TOC.",
        price: "R$ 1.000,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654071000.005802BR5918Silvio Casamassima6009SAO PAULO62140510yssVSt7R9f63049C8F",
    },
    {
        url: "gifts-16.png",
        description: "Roupa de cama tipo hotel chique, é verdade esse bilhete.",
        price: "R$ 1.253,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654071253.005802BR5918Silvio Casamassima6009SAO PAULO62140510leAhNCneJO63043D91",
    },
    {
        url: "gifts-17.png",
        description: "Garantindo a primeira janta na lua de mel.",
        price: "R$ 1.489,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654071489.005802BR5918Silvio Casamassima6009SAO PAULO621405106wyVaS6yiA6304DFDE",
    },
    {
        url: "gifts-18.png",
        description: "Ajuda o noivo a caprichar no hotel da lua de mel.",
        price: "R$ 2.648,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654072648.005802BR5918Silvio Casamassima6009SAO PAULO621405105q71hAvpHF6304C9F0",
    },
    {
        url: "gifts-19.png",
        description: "Se tiver inspirado.",
        price: "R$ 3.112,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654073112.005802BR5918Silvio Casamassima6009SAO PAULO62140510DnjVGslV1s63043BCD",
    },
    {
        url: "gifts-20.png",
        description: "Caixinha para perrengues na lua de mel.",
        price: "R$ 4.417,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654074417.005802BR5918Silvio Casamassima6009SAO PAULO62140510iCt0iNQoqG63045562",
    },
    {
        url: "gifts-21.png",
        description: "Há 3 anos sem TV na sala, nos ajude.",
        price: "R$ 5.259,00",
        isFlipped: false,
        isCopied: false,
        pixCode: "00020126700014BR.GOV.BCB.PIX0111012298679290233Presente casamento Bruna e Silvio52040000530398654075259.005802BR5918Silvio Casamassima6009SAO PAULO62140510pvr9bVabPN6304F50E",
    }
]

function GiftsCardsList() {
    const theme = useTheme();
    const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));

    const [items, setItems] = useState(lessThanMedium ? initialItems.slice(0, 5) : initialItems);
    
    function handleGiftClick(e, index) {
        e.preventDefault();
        const nextItems = items.map((item, i) => {
            item.isCopied = false;
            if (i === index) {
                item.isFlipped = !item.isFlipped;
            } else {
                item.isFlipped = false;
            }
            return item;
        });
        setItems(nextItems);
    }

    function handleGiftMoreClick(e) {
        e.preventDefault();

        const currentSize = items.length;
        const position = currentSize - 2;
        const nextStepSize = Math.min(21, currentSize + 4);
        setItems(initialItems.slice(0,nextStepSize));
        var top = document.getElementById('card-' + position).offsetTop; //Getting Y of target element
        window.scrollTo(0, top);
    }

    function handleCopyPixClick(e, index) {
        e.preventDefault();
        const nextItems = items.map((item, i) => {
            if (i === index) {
                item.isCopied = true;
            } 
            return item;
        });
        setItems(nextItems);
        navigator.clipboard?.writeText(items[index].pixCode)
    }

    return items.map((item, index) => (
        <>
            <Grid item xs={12} sm={6} md={4} key={'grid_' + item.url} id={'card-' + index}>
                <ReactCardFlip isFlipped={items[index].isFlipped} flipDirection="horizontal">
                    <Card sx={{ minHeight: 420 }} key={'card_' + item.url}>
                        <CardMedia sx={{ minHeight: 173, minWidth: 246 }} key={'cardmedia_' + item.url}
                            image={item.url}
                            title={item.url}>
                        </CardMedia>
                        <CardContent key={'cardcontent_' + item.url}>
                            <GiftsDescTextTypography>{item.description}</GiftsDescTextTypography>
                            <GiftsPriceTextTypography>{item.price}</GiftsPriceTextTypography>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'center', minHeight: 65}} key={'cardaction_' + item.url}>
                            <GiftsButton disableElevation variant="contained" key={'cardbutton_' + item.url} onClick={(e) => handleGiftClick(e, index)}><GiftsButtonTextTypography>PRESENTEAR</GiftsButtonTextTypography></GiftsButton>
                        </CardActions>
                    </Card>

                    <Card sx={{ minHeight: 420, position: 'relative' }} key={'card1_' + item.url} >
                        <CardContent  key={'cardcontent1_' + item.url}>
                            <GiftsPixTextTypography>Copie ou escaneia o Pix</GiftsPixTextTypography>
                            <GiftsPriceTextTypography>
                                {item.isCopied ?
                                    <>Copiado!</>
                                    :
                                    <Link style={{ cursor: 'pointer' }} underline="always" onClick={(e) => handleCopyPixClick(e, index)}>Copiar codigo</Link>
                                }
                            </GiftsPriceTextTypography>
                            <ImQRCodeGifts value={item.pixCode}></ImQRCodeGifts>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'center', minHeight: 65, position: 'absolute', bottom: 0, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto'}} key={'cardaction1_' + item.url} >
                            <GiftsButton sx={{marginTop: 0,  width: 148}} disableElevation variant="contained" key={'cardbutton1_' + item.url} onClick={(e) => handleGiftClick(e, index)}><GiftsButtonTextTypography>VOLTAR</GiftsButtonTextTypography></GiftsButton>
                        </CardActions>
                    </Card>
                </ReactCardFlip>
            </Grid>  
            {index +1 >= items.length && items.length < 21 &&
                <Grid item xs={12} >
                    <GiftsMoreButton sx={{margin: '0 auto', display: 'block'}} disableElevation variant="contained" onClick={(e) => handleGiftMoreClick(e)}><GiftsButtonTextTypography>VER MAIS</GiftsButtonTextTypography></GiftsMoreButton>
                </Grid>
            }
        </>
    ));
}

function GiftSection(props) {
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openMessageError, setOpenMessageError] = React.useState(false);

    function handleCopyTextClick(e, text) {
        e.preventDefault();
        navigator.clipboard?.writeText(text);
    }

    async function handleGiftsSendButton(e) {
        e.preventDefault();
        let content = textRef.current.value;
        textRef.current.value = "";
        if (!content) {
            return;
        }

        try {
            const docRef = await addDoc(collection(db, "messages"), {
                content: content
            });
            console.log("Document written with ID: ", docRef.id);
            setOpenSuccess(true);
            textRef.current.disabled = true;
        } catch (e) {
            console.error("Error adding document: ", e);
            setOpenMessageError(true);
        }
        
    }

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSuccess(false);
        setOpenMessageError(false);
    };

    const theme = useTheme();
    const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
    const textRef = useRef();
    const { t } = useTranslation();
    const language = props.language;
    return(
        <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        id="gifts">
            <Grid item xs={12}><ImgLemonGifts /></Grid>
            <Grid item xs={12}><GiftsTitleTextTypography>{t("gifts.title")}</GiftsTitleTextTypography></Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8}><GiftsDescTextTypography>{t("gifts.description")}</GiftsDescTextTypography></Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8}>
                {language !== 'it' &&
                <>
                    <Box style={{margin: '0px 0px 20px 0px'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}>
                            <Grid item xs={12} sm={10}>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        fullWidth
                                        id="outlined-multiline-static"
                                        label="Deixe um recadinho"
                                        multiline
                                        rows={lessThanMedium ? 6 : 2}
                                        variant="outlined"
                                        name="message"
                                        inputRef={textRef}
                                        />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <GiftsSendButton sx={{margin: '0 auto', display: 'block'}} disableElevation variant="contained" onClick={handleGiftsSendButton}><GiftsButtonTextTypography>ENVIAR</GiftsButtonTextTypography></GiftsSendButton>
                            </Grid>
                        </Grid>
                    </Box> 
                    <Divider></Divider>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={openSuccess}
                        autoHideDuration={20000}
                        onClose={handleClose}
                        message="Recadinho recebido pelos noivos, muito obrigado pelo carinho!"
                        action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                        }
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={openMessageError}
                        autoHideDuration={20000}
                        onClose={handleClose}
                        message="Ups, algo deu errado no envio do recadinho. Atualize a pagina e tente novamente!"
                        action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                        }
                    />
                </>
                }
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
            {language === 'it' &&
            <>
                <Grid item xs={1} sm={2}></Grid>
                <Grid item xs={10} sm={8}>
                    <GiftsIbanTextTypography onClick={(e) => handleCopyTextClick(e, t("gifts.description.a"))}><b>BENFICIARIO: </b>{t("gifts.description.a")}</GiftsIbanTextTypography>
                    <GiftsIbanTextTypography onClick={(e) => handleCopyTextClick(e, t("gifts.description.b"))}><b>IBAN: </b>{t("gifts.description.b")}</GiftsIbanTextTypography>
                    <GiftsIbanTextTypography onClick={(e) => handleCopyTextClick(e, t("gifts.description.c"))}><b>CAUSALE: </b>{t("gifts.description.c")}</GiftsIbanTextTypography>
                </Grid>
                <Grid item xs={1} sm={2}></Grid>

            </>
            }
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}>
                    {language !== 'it' &&
                        <GiftsCardsList />
                    }
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    );
}
export default GiftSection;